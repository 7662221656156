/* we are using our own close button so we don't need video-reacts */
.close-vid {
  display: none;
}
/* the large play button seems to only be accessible to mouse users so removing */
.video-react-big-play-button {
  display: none !important;
}
/* ensures that the control bar always renders, a11y issues if it doesn't */
.video-react-control-bar {
  display: flex !important;
  visibility: visible !important;
  opacity: 1 !important;
  transition: visibility 0.1s, opacity 0.1s !important;
}
/* DO NOT CHANGE THIS */
.video-react {
  height: 100vh !important;
  padding: 0px !important;
}
