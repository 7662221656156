/* custom rules for toast */
.toastify-content {
    bottom: 0px !important;
    background: none !important;
    box-shadow: none !important;
    padding: 0px !important;
  }
  
  .Toastify__toast-container--bottom-right {
    padding: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    min-height: 0 !important;
  }
  
  .Toastify__toast--default {
    background: #e8006b !important;
  }

  .Toastify__toast-body {
    color: #fff;
    padding: 12px;
    font-size: 16px;
  }
  
  .toaster-success {
    background: #09b198;
  }
  
  .toaster-warn {
    background: #ea9436;
  }
  
  .toaster-error {
    background: #e8006b;
  }
  